export const iitPrograms = [
  'All',
  'Aerospace Engineering',
  'Agricultural and Food Engineering',
  'Agricultural and Food Engineering with M.Tech. in any of the listed specializations',
  'Agricultural and Food Engineering with M.Tech. in any of the listedspecializations',
  'Applied Geology',
  'Applied Geophysics',
  'Applied Mathematics',
  'Architecture',
  'Artificial Intelligence',
  'Artificial Intelligence and Data Science',
  'Bio Engineering',
  'Bio Technology',
  'Biochemical Engineering and Biotechnology',
  'Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology',
  'Biochemical Engineering with M.Tech. in Biochemical Engineering andBiotechnology',
  'Bioengineering with M.Tech in Biomedical Technology',
  'Biological Engineering',
  'Biological Sciences',
  'Biological Sciences and Bioengineering',
  'Biotechnology and Biochemical Engineering',
  'BS in Mathematics',
  'Ceramic Engineering',
  'Chemical Engineering',
  'Chemical Science and Technology',
  'Chemistry',
  'Civil Engineering',
  'Civil Engineering and M. Tech. in Structural Engineering',
  'Civil Engineering and M.Tech in Transportation Engineering',
  'Civil Engineering and M.Tech. in Environmental Engineering',
  'Civil Engineering with any of the listed specialization',
  'Civil Engineering with M.Tech. in Structural Engineering',
  'Computer Science and Engineering',
  'Data Science and Engineering',
  'Data Science and Artificial Intelligence',
  'Data Science and Engineering',
  'Statistics and Data Science',
  'Earth Sciences',
  'Economics',
  'Electrical and Electronics Engineering',
  'Electrical Engineering',
  'Electrical Engineering - Power and Automation',
  'Electrical Engineering and M.Tech Power Electronics and Drives',
  'Electrical Engineering with M.Tech. in any of the listed specializations',
  'Electrical Engineering with M.Tech. in Communications and Signal Processing',
  'Electrical Engineering with M.Tech. in Microelectronics',
  'Electrical Engineering with M.Tech. in Power Electronics',
  'Electronics and Communication Engineering',
  'Electronics and Electrical Communication Engineering',
  'Electronics and Electrical Communication Engineering with M.Tech. in any of the listed specializations',
  'Electronics and Electrical Communication Engineering with M.Tech. in anyof the listed specializations',
  'Electronics and Electrical Engineering',
  'Electronics and Instrumentation Engineering',
  'Electronics Engineering',
  'Energy Engineering with M.Tech. in Energy Systems Engineering',
  'Engineering Design',
  'Engineering Physics',
  'Engineering Physics and M.Tech. with specialization in Nano Science',
  'Engineering Science',
  'Environmental Engineering',
  'Environmental Science and Engineering',
  'Exploration Geophysics',
  'Geological Technology',
  'Geophysical Technology',
  'Industrial and Systems Engineering',
  'Industrial and Systems Engineering with M.Tech. in Industrial and Systems Engineering and Management',
  'Industrial and Systems Engineering with M.Tech. in Industrial and SystemsEngineering and Management',
  'Industrial Chemistry',
  'Instrumentation Engineering',
  'Manufacturing Science and Engineering',
  'Manufacturing Science and Engineering with M.Tech. in Industrial and Systems Engineering and Management',
  'Manufacturing Science and Engineering with M.Tech. in Industrial andSystems Engineering and Management',
  'Materials Science and Engineering',
  'Materials Science and Metallurgical Engineering',
  'Materials Science and Technology',
  'Mathematics & Computing',
  'Mathematics and Computing',
  'Mathematics and Scientific Computing',
  'Mechanical Engineering',
  'Mechanical Engineering and M. Tech. in Mechanical System Design',
  'Mechanical Engineering and M. Tech. in Thermal Science & Engineering',
  'Mechanical Engineering and M.Tech. in Computer Integrated Manufacturing',
  'Mechanical Engineering and M.Tech. in Computer IntegratedManufacturing',
  'Mechanical Engineering with M.Tech. in any of the listed specializations',
  'Mechanical Engineering with M.Tech. in Manufacturing Engineering',
  'Metallurgical and Materials Engineering',
  'Metallurgical and Materials Engineering and M.Tech. in Materials Science and Engineering',
  'Metallurgical Engineering',
  'Metallurgical Engineering & Materials Science',
  'Metallurgical Engineering and Materials Science',
  'Metallurgical Engineering and Materials Science with M.Tech. in Ceramics and Composites',
  'Metallurgical Engineering and Materials Science with M.Tech. in Metallurgical Process Engineering',
  'Mineral Engineering',
  'Mining Engineering',
  'Mining Machinery Engineering',
  'Mining Safety Engineering',
  'Naval Architecture and Ocean Engineering',
  'Ocean Engineering and Naval Architecture',
  'Petroleum Engineering',
  'Pharmaceutical Engineering & Technology',
  'Pharmaceutics',
  'Physics',
  'Polymer Science and Engineering',
  'Production and Industrial Engineering',
  'Quality Engineering Design and Manufacturing',
  'Energy Engineering',
  'Engineering and Computational Mechanics',
  'Textile Technology',
  'Materials Engineering',
  'Biomedical Engineering',
  'Biotechnology and Bioinformatics',
  'Computational Engineering',
  'Electrical Engineering - IC Design and Technology',
  'Chemistry with Specialization',
  'Civil and Infrastructure Engineering',
  'Physics with Specialization',
  'Biosciences and Bioengineering',
  'Chemical Sciences',
  'Mineral and Metallurgical Engineering',
  'Mechatronics Engineering',
  'Chemical and Biochemical Engineering',
  'Interdisciplinary Sciences'
]

export const nitPrograms = [
  'All',
  'Bio Technology',
  'Chemical Engineering',
  'Civil Engineering',
  'Computer Science and Engineering',
  'Electrical Engineering',
  'Electronics and Communication Engineering',
  'Industrial and Production Engineering',
  'Information Technology',
  'Instrumentation and Control Engineering',
  'Mechanical Engineering',
  'Textile Technology',
  'Architecture',
  'Metallurgical and Materials Engineering',
  'Materials Science and Metallurgical Engineering',
  'Planning',
  'Production and Industrial Engineering',
  'Bio Engineering',
  'Chemistry',
  'Electronics and Instrumentation Engineering',
  'Engineering Physics',
  'Mathematics',
  'Mathematics and Data Science',
  'Computational Mathematics',
  'Artificial Intelligence',
  'Physics',
  'Production Engineering',
  'Electrical and Electronics Engineering',
  'Biotechnology',
  'Materials Science and Engineering',
  'Mining Engineering',
  'Bio Medical Engineering',
  'Electronics and Telecommunication Engineering',
  'Metallurgical Engineering',
  'Computer Engineering',
  'Ceramic Engineering',
  'Ceramic Engineering and M.Tech Industrial Ceramic',
  'Food Process Engineering',
  'Industrial Design',
  'Life Science',
  'Material Science',
  'Mathematics & Computing',
  'Civil Engineering and M.Tech in Transportation Engineering',
  'Civil Engineering and M.Tech in Water Resources',
  'Computer Science and Engineering and M.Tech Computer Science',
  'Computer Science and Engineering and M.Tech in Information Security',
  'Electrical Engineering and M.Tech Control and Automation',
  'Electrical Engineering and M.Tech Electronic Systems and Communications',
  'Electrical Engineering and M.Tech Power Electronics and Drives',
  'Electronics and Communication Engineering and M.Tech Communication and Network',
  'Electronics and Instrumentation Engineering and M.Tech VLSI Design and Embedded',
  'Mechanical Engineering and M.Tech Mechatronics and Automation',
  'Biotechnology and Biochemical Engineering',
  'Computational and Data Science',
  'Civil Engineering - Construction Technology and Management',
  'Computer Science and Engineering - Cyber Security',
  'Computer Science and Engineering - Data Science',
  'Electrical Engineering - Power System Engineering',
  'Electronics and Communication Engineering - Microelectronics and VLSI System Design',
  'Mechanical Engineering - Manufacturing and Industrial Engineering',
  'Material Science and Engineering',
  'Engineering and Computational Mechanics',
  'Mathematics and Computing Technology'
]

export const iitProgramDropDownOptions = iitPrograms.map(function (element) {
  return {
    key: iitPrograms.indexOf(element),
    text: element,
    value: element
  }
})

export const nitProgramDropDownOptions = nitPrograms.map(function (element) {
  return {
    key: nitPrograms.indexOf(element),
    text: element,
    value: element
  }
})
